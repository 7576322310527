app-register {
  .register {
    @include media-breakpoint-up(md) {
      .card {
        position: absolute;
      }
    }

    &-header,
    &-content {
      background: map-get($colors, primary);
    }

    &-header {
      position: relative;
      padding: 0rem 0 13rem 0;

      @include media-breakpoint-up(md) {
        padding: 5rem 0 13rem 0;
      }
    }

    &-content {
      padding: 13rem 0 10rem 0;

      &-curve {
        border-top-left-radius: 30vh;
      }
    }

    &-curves {
      position: absolute;
      width: 100%;
      height: 100%;

      &--bottom {
        background: var(--bs-light);
        top: 0;

        @include media-breakpoint-up(md) {
          border-bottom-right-radius: 100vh;
        }
      }
    }

    &-content,
    &-footer {
      color: white;
    }

    &-footer {
      background: darken(map-get($colors, primary), 20);
    }
  }
}
