app-public {
  @include media-breakpoint-down(sm) {
    .references .references-content.container,
    .reference-requests .reference-requests-content.container {
      padding-bottom: 30px;
    }
  }

  .ql-editor {
    height: auto;
  }

  .ql-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .card p a {
    color: darken(map-get($colors, primary), 10) !important;
  }
}
