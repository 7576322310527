.references,
.forms {
  .btn-link {
    cursor: pointer !important;
  }
}

app-forms,
app-feedbacks-show,
app-pre-screenings-show,
app-references,
app-sidebar-reference-question-set,
app-sidebar-feedback-question-set,
.modals-questions {
  .rating {
    &-label {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      font-size: x-small;
      &.scale-5 {
        max-width: 245px;
      }
      &.scale-10 {
        max-width: 510px;
      }
    }
    &--disabled {
      .btn {
        cursor: default !important;
      }
    }

    .btn {
      @include transition;
      border: 0 !important;
    }

    .v-btn-rating {
      color: white;

      &--hover {
        &--5 {
          &--1:hover {
            color: white;
            background-color: map-get($colors, danger);
          }

          &--2:hover {
            color: white;
            background-color: map-get($colors, warning);
          }

          &--3:hover {
            color: white;
            background-color: #f1c40f;
          }

          &--4:hover {
            color: white;
            background-color: #97e031;
          }

          &--5:hover {
            color: white;
            background-color: map-get($colors, success);
          }
        }

        &--10 {
          &--1:hover,
          &--2:hover {
            color: white;
            background-color: map-get($colors, danger);
          }

          &--3:hover,
          &--4:hover {
            color: white;
            background-color: map-get($colors, warning);
          }

          &--5:hover,
          &--6:hover {
            color: white;
            background-color: #f1c40f;
          }

          &--7:hover,
          &--8:hover {
            color: white;
            background-color: #97e031;
          }

          &--9:hover,
          &--10:hover {
            color: white;
            background-color: map-get($colors, success);
          }
        }
      }

      &--5 {
        &--1 {
          background-color: map-get($colors, danger);
        }

        &--2 {
          background-color: map-get($colors, warning);
        }

        &--3 {
          background-color: #f1c40f;
        }

        &--4 {
          background-color: #97e031;
        }

        &--5 {
          background-color: map-get($colors, success);
        }
      }

      &--10 {
        &--1,
        &--2 {
          background-color: map-get($colors, danger);
        }

        &--3,
        &--4 {
          background-color: map-get($colors, warning);
        }

        &--5,
        &--6 {
          background-color: #f1c40f;
        }

        &--7,
        &--8 {
          background-color: #97e031;
        }

        &--9,
        &--10 {
          background-color: map-get($colors, success);
        }
      }
    }
  }
}
