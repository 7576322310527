.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
}

.dropdown-menu {
  min-width: 250px;
  width: auto;
  max-height: 300px;
  overflow-y: auto;
}
