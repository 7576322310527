.select2-dropdown.select2-dropdown--below {
  width: 355px !important;
}

.select2-container--default[_ngcontent-cxb-c126] .select2-selection--single[_ngcontent-cxb-c126] {
  border: none !important;
}

.select2-selection.select2-selection--single {
  border: none !important;
}

.select2-dropdown.select2-dropdown--below {
  margin-left: -13px !important;
  margin-top: 13px !important;
}

select2.form-control {
  padding-top: 17px !important;
}

#select2-2.form-control,
#select2-2.form-select {
  line-height: none !important;
}

.select2-dropdown.select2-dropdown--below {
  border-top: 1px solid #ccc !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.select2-dropdown {
  border: 1px solid #ccc !important;
}

.select2-container .select2-search .select2-search__field {
  border: 1px solid #ccc !important;
}

.card.v-card.mb-4.v-card--highlightable.v-card--highlighted {
  z-index: 10000000 !important;
}
